<template>
    <div>
            <el-dialog :visible.sync="isShow" width="80%" :before-close="close" center  :destroy-on-close="true">
                       <span  > 交通变更记录</span>
                       <el-table :data="routeArr" style="width: 100%;margin-top:10px" border :header-cell-style="header" >
                           <el-table-column  label="序号" width="50">
                                 <template slot-scope="scope">
                                        <span  v-if="scope.row.id && scope.row.changeid"> {{scope.$index+1}}</span>
                                        <span  v-if="scope.row.id && !scope.row.changeid" class="travelChangeInfoDel"> 删</span>
                                        <span  v-if="scope.row.changeid && !scope.row.id" class="travelChangeInfoDel" style="background: #f5a337;"> 增</span>

                                 </template>
                             </el-table-column>
                            <el-table-column  label="出发日期" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.departDate}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.departDate==scope.row.changedepartDate)"> {{showText(scope.row.changedepartDate)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="到达日期">
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.arriveDate}}</span>
                                        <span style="color: #f5a337;"  v-show="!(scope.row.arriveDate==scope.row.changearriveDate)" > {{showText(scope.row.changearriveDate)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="出行方式" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;">{{moduleList.routeConfig[scope.row.proType]}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.changeproType==scope.row.proType)" > {{showText(scope.row.changeproType)}}</span>
                                 </template>
                             </el-table-column>
                             <el-table-column  label="出行人">
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.cxr}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.changecxr==scope.row.cxr)"  > {{showText(scope.row.changecxr)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="出发地" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.departCityName}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.departCityName==scope.row.changedepartCityName)" >  {{showText(scope.row.changedepartCityName)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="到达地" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.arriveCityName}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.arriveCityName==scope.row.changearriveCityName)" > {{showText(scope.row.changearriveCityName)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="航班/车次" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.flightNo}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.flightNo==scope.row.changeflightNo)" > {{showText(scope.row.changeflightNo)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="舱位/席别" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.cabin}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.cabin==scope.row.changecabin)" >{{showText(scope.row.changecabin)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="国内交通费" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.fyys || 0}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.fyys==scope.row.changefyys)" >  {{showText(scope.row.changefyys)}} </span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="打车费" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.dcf || 0}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.dcf==scope.row.changedcf)" >{{showText(scope.row.changedcf)}} </span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="出差类型" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{  scope.row.cclxMc}}</span>
                                        <span style="color: #f5a337;" v-show="scope.row.cclx != scope.row.changecclx" >{{showText(scope.row.changecclxMc)}} 

                                        </span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="出差事由" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{ scope.row.ccsy}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.ccsy==scope.row.changeccsy)" > {{showText(scope.row.changeccsy)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="住宿类型" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{  moduleList.fliterRoomsType[scope.row.zssm]}}</span>
                                        <span style="color: #f5a337;" v-show="!(moduleList.fliterRoomsType[scope.row.zssm]==moduleList.fliterRoomsType[scope.row.changezssm])" >{{showText(moduleList.fliterRoomsType[scope.row.changezssm])}} (变更后){{moduleList.fliterRoomsType[scope.row.changezssm]}}</span>
                                 </template>
                             </el-table-column>
                    </el-table>
                   <div style="margin-top:20px"> 住宿变更记录</div>
                       <el-table :data="htRouteArr" style="width: 100%;margin-top:10px" border :header-cell-style="header">
                                <el-table-column  label="序号" width="50">
                                 <template slot-scope="scope">
                                        <span  v-if="scope.row.id && scope.row.changeid"> {{scope.$index+1}}</span>
                                        <span  v-if="scope.row.id && !scope.row.changeid" class="travelChangeInfoDel"> 删</span>
                                        <span  v-if="scope.row.changeid && !scope.row.id" class="travelChangeInfoDel"> 增</span>
    
                                 </template>
                             </el-table-column>
                            <el-table-column  label="入住日期" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.departDate}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.departDate==scope.row.changedepartDate && scope.row.changedepartDate)"> {{showText(scope.row.changedepartDate)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="离店日期">
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.arriveDate}}</span>
                                        <span style="color: #f5a337;"  v-show="!(scope.row.arriveDate==scope.row.changearriveDate)" > {{showText(scope.row.changearriveDate)}}</span>
                                 </template>
                             </el-table-column>
                             <el-table-column  label="出行人">
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.cxr}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.changecxr==scope.row.cxr)"  >{{showText(scope.row.changecxr)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="入住城市" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.departCityName}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.departCityName==scope.row.changedepartCityName)" >>{{showText(scope.row.changedepartCityName)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="酒店名称" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.flightNo}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.flightNo==scope.row.changeflightNo)" >{{showText(scope.row.changeflightNo)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="房型" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.cabin}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.cabin==scope.row.changecabin)" >{{showText(scope.row.changecabin)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="房间数量" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{  scope.row.fjs}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.fjs==scope.row.changefjs)" >{{showText(scope.row.changefjs)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="住宿费" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{ scope.row.fyys || 0}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.fyys==scope.row.changefyys)">{{showText(scope.row.changefyys)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="出差餐费" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{  scope.row.cf || 0}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.cf==scope.row.changecf)" > {{showText(scope.row.changecf)}}</span>
                                 </template>
                             </el-table-column>
                    </el-table>
                   <div style="margin-top:20px"> 审批规则</div>
                       <el-table :data="applyList" style="width: 100%;margin-top:10px" border :header-cell-style="header">
                            <el-table-column  type="index" width="50" label="序号"></el-table-column>
                            <el-table-column  label="审批人" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{  scope.row.vipdeptName}}{{  scope.row.empNo}}{{  scope.row.empName}}</span>
                                 </template>
                             </el-table-column>
                            
                    </el-table>
            <span slot="footer" class="dialog-footer">
                  <el-button @click="close">关闭</el-button>
            </span>
            </el-dialog>

    </div>
</template>

<script>
	import {mapState,mapGetters} from 'vuex';
    import moduleList from './moduleList'
	export default{
		name: 'CcsqdHeader',
		props:{
			isShow:{
				type: Boolean,
				default(){
					return false
				}
			},
			changeId:{
				type: String,
				default:'',
			}
		},
		
		data(){
			return {
                header:{
					background:'#FAFAFA',
					height:40+'px',
				},
                appId:'',// 审批规则id
                applyData:'',
                applyList:[],
                changeRouteList:{},
                routeArr:[],
                htRouteArr:[],
                moduleList,
			}
		},
		computed:{

		},
        created(){
        },
		mounted() {
            
		},
		watch:{
            isShow(value){
                if(value){
                    this.getInfo()
                }
            }
		},
		methods:{
                showText(val){
                    if(val){
                        return '(变更后)'+val
                    }else{
                        return ''
                    }
                },
                getInfo(){ // 
                    let _this = this;
                    let info = this.$conn.getConn("xakCcsqd.fastBookChangeDetails");
                    info({ changeId: this.changeId}, res => {
                        let  list = res.data.changeRouteList || [];
                        _this.appId=res.data.appId
                        _this.appRulesSpgz(this.appId)
                        _this.routeArr = [];
						_this.htRouteArr = [];
                        // changeRouteList:[[{},{}],[{},{}]]
                        list.forEach(item=>{
                            let routeList=item || []
                            let obj={}
                            item.forEach((e,index)=>{
                                let route=e
                                if(index==0){
                                    if(JSON.stringify(route)=='{}'){
                                        obj.isAdd=true
                                    }else{
                                        for(let k in route ){
                                            obj[k]=route[k]
                                        }
                                    }
                                }else{
                                    for(let i in route){
                                        obj['change'+i]=route[i]
                                        
                                    }
                                }
                            })
                            if (obj.proType == 10903 || obj.changeproType == 10903) {
								_this.htRouteArr.push(obj);
							} else {								
								_this.routeArr.push(obj);
							}
      
                        })
                        console.log(_this.htRouteArr,"this.htRouteArr",_this.routeArr,"this.routeArr")
                    });
                   
            },
            
            close(){
                this.$emit('update:isShow',false)
            },
            			// 审批人信息
			appRulesSpgz(appId){
				let params = {
					spgzId: appId
				}
				this.$conn.getConn('commu.appRulesDetail')(params,(res)=>{
					this.applyData = res.data || {};
					this.applyList = res.data.gznr[0]?.empList??[];
				},(err)=>{
					
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
.travelChangeInfoDel {
	display: inline-block;
	width: 16px; 
	height: 16px;
	background: #ff6666;
	color: #fff;
	line-height: 16px;
	font-size: 12px; 
	text-align: center;
}

</style>
