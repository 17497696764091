<template>
	<div class="ad-main-c">
		<div class="main">
			<el-card>
				<div class="marB10 flex ">
					<p class="marB10">变更单号：<span style="color:#0783dd;">{{dataDetail.changeNo}}</span></p>
                    <p class="ml20">审批状态：<span style="color:#0783dd;"> {{dataDetail.vipStatus==0?'待送审':'审批中'}}</span></p>
                    <p class="ml20">变更原因：<span > {{dataDetail.changeYy}}</span></p>
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						交通计划
					</div>
					<div class="xc-main">
						 <el-table :data="routeArr" style="width: 100%;margin-top:10px" border :header-cell-style="header" >
                            <el-table-column  label="序号" width="50">
                                 <template slot-scope="scope">
                                        <span  v-if="scope.row.id && scope.row.changeid"> {{scope.$index+1}}</span>
                                        <span  v-if="scope.row.id && !scope.row.changeid" class="travelChangeInfoDel"> 删</span>
                                        <span  v-if="scope.row.changeid && !scope.row.id" class="travelChangeInfoDel" style="background: #f5a337;"> 增</span>

                                 </template>
                             </el-table-column>
                            <el-table-column  label="出发日期" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.departDate}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.departDate==scope.row.changedepartDate)"> {{showText(scope.row.changedepartDate)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="到达日期">
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.arriveDate}}</span>
                                        <span style="color: #f5a337;"  v-show="!(scope.row.arriveDate==scope.row.changearriveDate)" > {{showText(scope.row.changearriveDate)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="出行方式" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;">{{moduleList.routeConfig[scope.row.proType]}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.changeproType==scope.row.proType)" > {{showText(scope.row.changeproType)}}</span>
                                 </template>
                             </el-table-column>
                             <el-table-column  label="出行人">
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.cxr}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.changecxr==scope.row.cxr)"  > {{showText(scope.row.changecxr)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="出发地" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.departCityName}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.departCityName==scope.row.changedepartCityName)" >  {{showText(scope.row.changedepartCityName)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="到达地" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.arriveCityName}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.arriveCityName==scope.row.changearriveCityName)" > {{showText(scope.row.changearriveCityName)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="航班/车次" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.flightNo}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.flightNo==scope.row.changeflightNo)" > {{showText(scope.row.changeflightNo)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="舱位/席别" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.cabin}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.cabin==scope.row.changecabin)" >{{showText(scope.row.changecabin)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="国内交通费" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.fyys ||0}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.fyys==scope.row.changefyys)" >  {{showText(scope.row.changefyys)}} </span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="打车费" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.dcf || 0}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.dcf==scope.row.changedcf)" >{{showText(scope.row.changedcf)}} </span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="出差类型" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{  moduleList.filtergoType[scope.row.cclx]}}</span>
                                        <span style="color: #f5a337;" v-show="!(moduleList.filtergoType[scope.row.cclx]==moduleList.filtergoType[scope.row.changecclx])" >{{showText(moduleList.filtergoType[scope.row.changecclx])}} 

                                        </span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="出差事由" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{ scope.row.ccsy}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.ccsy==scope.row.changeccsy)" > {{showText(scope.row.changeccsy)}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="住宿类型" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{  moduleList.fliterRoomsType[scope.row.zssm]}}</span>
                                        <span style="color: #f5a337;" v-show="!(moduleList.fliterRoomsType[scope.row.zssm]==moduleList.fliterRoomsType[scope.row.changezssm])" >{{showText(moduleList.fliterRoomsType[scope.row.changezssm])}} (变更后){{moduleList.fliterRoomsType[scope.row.changezssm]}}</span>
                                 </template>
                             </el-table-column>
                    </el-table>
					</div>	
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						住宿安排
					</div>
					<div class="xc-main">
                        <!-- 序号	入住日期	离店日期	入住晚数	入住城市	酒店名称	房型	房间数量	住宿费	 	出差餐费	订单号	原订单操作	新订单操作	打卡记录 -->
						<el-table :data="htRouteArr" style="width: 100%;margin-top:10px" border :header-cell-style="header">
                            <el-table-column  label="序号" width="50">
                                 <template slot-scope="scope">
                                        <span  v-if="scope.row.id && scope.row.changeid"> {{scope.$index+1}}</span>
                                        <span  v-if="scope.row.id && !scope.row.changeid" class="travelChangeInfoDel"> 删</span>
                                        <span  v-if="scope.row.changeid && !scope.row.id" class="travelChangeInfoDel" style="background: #f5a337;"> 增</span>

                                 </template>
                             </el-table-column>
                            <el-table-column  label="入住日期" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.departDate}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.departDate==scope.row.changedepartDate) && scope.row.changedepartDate">(变更后){{scope.row.changedepartDate}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="离店日期">
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.arriveDate}}</span>
                                        <span style="color: #f5a337;"  v-show="!(scope.row.arriveDate==scope.row.changearriveDate) && scope.row.changearriveDate" >(变更后){{scope.row.changearriveDate}}</span>
                                 </template>
                             </el-table-column>
                             <el-table-column  label="出行人">
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.cxr}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.changecxr==scope.row.cxr) && scope.row.changecxr"  >(变更后){{scope.row.changecxr}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="入住城市" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.departCityName}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.departCityName==scope.row.changedepartCityName)  && scope.row.changedepartCityName" >(变更后){{scope.row.changedepartCityName}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="酒店名称" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.flightNo}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.flightNo==scope.row.changeflightNo)  && scope.row.changeflightNo" >(变更后){{scope.row.changeflightNo}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="房型" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{scope.row.cabin}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.cabin==scope.row.changecabin) && scope.row.changecabin" >(变更后){{scope.row.changecabin}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="房间数量" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{  scope.row.fjs}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.fjs==scope.row.changefjs) && scope.row.changefjs" >(变更后){{scope.row.changefjs}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="住宿费" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{ scope.row.fyys || 0}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.fyys==scope.row.changefyys)  && scope.row.changefyys" >(变更后){{scope.row.changefyys}}</span>
                                 </template>
                             </el-table-column>
                            <el-table-column  label="出差餐费" >
                                 <template slot-scope="scope">
                                        <span style="color: #808080;" >{{  scope.row.cf || 0}}</span>
                                        <span style="color: #f5a337;" v-show="!(scope.row.cf==scope.row.changecf) && scope.row.changecf" >(变更后){{scope.row.changecf}}</span>
                                 </template>
                             </el-table-column>
                    </el-table>
					</div>	
				</div>

				<div class="marB10 border flex h80">
					<div class="leftTitle">
						费用合计
					</div>
					<div class="ys-main">
						<div class="xc-ys">
							<span class="bfb-w25">交通预算: ￥{{dataDetail.jtys}}</span>
							<span class="bfb-w25">住宿预算: ￥{{dataDetail.zsys}}</span>
                            <span class="bfb-w25">餐饮预算: ￥{{dataDetail.cyys}}</span>
							<span class="bfb-w25">其他预算: ￥{{dataDetail.qtys}}</span>
						</div>
						<div class="xc-ys">
                            <span class="bfb-w25">预计本次出差总预算：￥{{dataDetail.bghzys}}</span>
                            <span class="bfb-w25">原出差预算：￥{{dataDetail.bgqzys}}</span>
							
						</div>
					</div>	
				</div>
				
				<div class="marB10 border flex h80" >
					<div class="leftTitle">
						审批进度
					</div>
					<yj-approval-progress class="sp-main" 
						:orderId="query.orderId"
						:orderType="query.orderType"
						:showProgress="showProgress"
						:resetspjd="resetspjd"
						></yj-approval-progress>
				</div>
				<!-- <div class="marB10 border flex h80" v-if="query.workItemId && dataDetail.vipStatus==1 && ifApprover==1 && !outTime">
					<div class="leftTitle">
						是否审批
					</div>
					<div class="sp-main flex">
						<div class="ss-item">
							<div>
								<el-radio label="1" v-model="active">同意</el-radio>
							</div>
							<div>
								<el-radio label="0" v-model="active">拒绝</el-radio>
							</div>
						</div>
						<div class="ss-right">
							<el-input
							  type="textarea"
							  :rows="2"
							  placeholder="请输入内容"
							  v-model="appRemark">
							</el-input>
						</div>
					</div>	
				</div>
                <div class="allBtn">
                    <el-button class="btn" style="margin-left:20px" @click="closeHandler">关闭</el-button>
                    <div style="margin:20px 0;" v-if="query.workItemId && dataDetail.vipStatus==1 && ifApprover==1 && !outTime">
                        <el-button class="btn" style="margin-left:20px" type="primary" @click="approvalSend">提交</el-button>
                    </div>
                </div> -->

                <yj-approval-cao-zuo 
					:appType="query.appType" 
					:workItemId="query.workItemId" 
					:orderType="11098" 
					:orderId="query.orderId"
					@closePage="spResetDetail" >
					<template slot="orderFooterSlot">
						<div class=" pad20" style="text-align: center;">

							<el-button class="btn" @click="closeHandler">关闭</el-button>
							<el-button class="btn" type="primary" v-if="!query.workItemId" @click="printHandler">打印</el-button>
							<el-button class="btn" v-if="query.workItemId && dataDetail.status==1 && ifApprover==1 && !outTime" type="primary" @click="approvalSend">提交</el-button>
							<el-button class="btn" v-if="dataDetail.status==2  && isShowChange" type="primary" @click="changeOrder">申请变更 </el-button>
							<el-button class="btn" v-if="dataDetail.status==2  && isShowChange" type="primary" @click="withDraw">取消</el-button>
							<el-button class="btn" v-if="dataDetail.status==1  && dataDetail.cancelFlag" type="primary" @click="revokeCcsqd()">撤回</el-button>
							<el-button class="btn" style="width: auto;" type="primary" @click="lookCcsqdTpl()">下载申请单模板</el-button>

						</div>	
					</template>
				</yj-approval-cao-zuo>

			</el-card>
		</div>

        <el-dialog
            title="打卡记录"
            :visible.sync="isShowCard"
            width="30%"
            center>
               <el-descriptions class="margin-top" title="出发地打卡" :column="4" direction="horizontal">
                    <el-descriptions-item label="打卡位置" :span="24">{{clockInDetails.beginAddress}}</el-descriptions-item>
                    <el-descriptions-item label="打卡时间" :span="24">{{clockInDetails.beginDate}}</el-descriptions-item>
                    <el-descriptions-item label="备注" :span="24">{{clockInDetails.beginRemark}}</el-descriptions-item>
                    <el-descriptions-item label="附件">
                        <el-image style="width: 100px; height: 100px" :src="clockInDetails.beginImgUrl" ></el-image>
                    </el-descriptions-item>
                </el-descriptions>
            <el-descriptions class="margin-top" title="到达地打卡" :column="4" direction="horizontal">
                    <el-descriptions-item label="打卡位置" :span="24">{{clockInDetails.endAddress}}</el-descriptions-item>
                    <el-descriptions-item label="打卡时间" :span="24">{{clockInDetails.endDate}}</el-descriptions-item>
                    <el-descriptions-item label="备注" :span="24">{{clockInDetails.endinRemark}}</el-descriptions-item>
                    <el-descriptions-item label="附件">
                        <el-image style="width: 100px; height: 100px" :src="clockInDetails.endImgUrl" ></el-image>
                    </el-descriptions-item>
                </el-descriptions>
        </el-dialog>
        <change-dialog :isShow.sync="isShowChangeDialog" :changeId="changeId"> </change-dialog>
	</div>
</template>

<script>
    import changeDialog from './ccsqdModule/ChangeDialog.vue';
import moduleList from './ccsqdModule/moduleList.js';
	export default{
		name:'CcsqdDetail',
        components: {
		    changeDialog,
		},

		data(){
			return {
				header:{
					background:'#FAFAFA',
					height:40+'px',
				},
                minEmpCxr:{ //最低职级

                },


                appid:'',
                changeId:'',
                clockInDetails:{},
                changeListData:[],
                isShowChangeDialog:false,
                isShowCard:false,
				dataDetail:{}, // 详情信息
				jhList:[], //行程列表
				hotelList:[], // 住宿列表
				carList:[], //用车列表
				approvalInfo:{}, //审批进度信息 
				status:1, // 审批人 审批高度
				
				active:'1', // 是否同意
				appRemark:'',// 审批备注
				outTime: false, // 审批是否已过期
                routeArr : [],
				htRouteArr : [],
				gzdetail:{
					appId:'',
					currentLevel:1,
					flowState: 3,
				},
                moduleList,
				resetspjd:false,
			}
		},

		computed:{
			// 接收传值
			query(){
				return this.$route.query || {};
			},
			// 
			empInfo(){
				let userInfo = this.$common.sessionget('user_info')
				return userInfo.empInfo;
			},
			//当前登录人是否是审批人
			ifApprover(){
				return this.empInfo.ifApprover || 0;
			},
            routeDiffTime(){
                return function(item){
                    const m1 = this.$moment(item.dateBegin);
                    const m2 = this.$moment(item.dateEnd);
                    const day = m2.diff(m1, 'd');
                    return day
                }
            },
            routeDiffTime2(){
                return function(item){
                    const m1 = this.$moment(item.departDate);
                    const m2 = this.$moment(item.arriveDate);
                    const day = m2.diff(m1, 'd');
                    return day
                }
            },

            proTypeConfig(){
                    return function(type){
                    let routeConfig = {
                            "10904": '火车',
                            '10901': '飞机',
                            '10903': '酒店',
                            '109991': '大巴',
                            "109992": '轮船',
                            "109993": '自驾',
                            "10999": '其他'
                        }
                    return routeConfig[type]
                } 
            },
            changeZtConfig(){
                return function(item){
                    let changeZt ={
                        // 变更单状态0草稿；1审批中；2审批通过；3审批拒绝；4已删除 ,
                        "0":"草稿",
                        "1":"审批中",
                        "2":"审批通过",
                        "3":"审批拒绝",
                        "4":"已删除",

                    }
                    return changeZt[item]
                }
            },


     
            zzsmType(){
                return function(type){
                    return this.moduleList.roomsTypeList.filter(item=>item.value==type)[0].name
                }
            },
            cclxType(){
                return function(type){
                    return this.moduleList.goTypeList.filter(item=>item.value==type)[0].name
                }
            }
		},
		mounted(){
			this.outTime = (this.query.appType == 3)
			this.getCcsqdInfo();
            // this.ChangeList()
            
			// this.getOrderAppProgress();
		},
		methods:{
            spResetDetail(){
				this.$router.replace({
					path: this.$route.path,
					query: {
						...this.$route.query,
						appType:2  // 已审批     0 订单详情 1.待审批 2.已审批 3. 审批过期 
	
					}
				})
				// 审批成功 通知父页面 刷新
				window.opener.needMyApprovalRefresh(1);
				this.getCcsqdInfo();
				this.resetspjd = true;
			},
                showText(val){
                    if(val){
                        return '(变更后)'+val
                    }else{
                        return ''
                    }
                },
            changeList(){
                    this.$conn.getConn('xakCcsqd.fastBookChangeList')({ccsqdId:this.dataDetail.id},res=>{
                            if(res.data){
                                this.changeListData=res.data
                            }
                    });
            },
            changeOrder(){

                let routerUrl = this.$router.resolve({
					path: '/xakCcsqdChange',
                    query:{ccsqdId:this.dataDetail.id}
				})
				window.open(routerUrl.href, "_blank")

            },
            openShowDialong(item){
                this.isShowChangeDialog=true
                this.changeId=item.id
            },
            // isShowChangeDialog=true
            
            // fastBookChangeList
            closeHandler(){
      				this.$confirm('此操作将关闭本页面, 是否继续?', '提示', {
							  confirmButtonText: '确定',
							  cancelButtonText: '取消',
							  type: 'warning'
				        }).then(() => {
							window.close();
				        }).catch(() => {
				            this.$message({
				            type: 'info',
				            message: '已关闭'
				        });          
				});
            },


			// 出差申请单详情
			getCcsqdInfo(){
				let queryParams = {};
					queryParams = {
						changeId: this.query.orderId
					}
				this.$conn.getConn('xakCcsqd.fastBookChangeDetails',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || {};
					this.dataDetail = data;
                    let  list = res.data.changeRouteList || [];
                        this.appId=res.data.appId
                        this.routeArr = [];
						this.htRouteArr = [];
                        // changeRouteList:[[{},{}],[{},{}]]
                        list.forEach(item=>{
                            let routeList=item || []
                            let obj={}
                            item.forEach((e,index)=>{
                                let route=e
                                if(index==0){
                                    if(JSON.stringify(route)=='{}'){
                                        obj.isAdd=true
                                    }else{
                                        for(let k in route ){
                                            obj[k]=route[k]
                                        }
                                    }
                                }else{
                                    for(let i in route){
                                        obj['change'+i]=route[i]
                                    }
                                }
                            })
                            if (obj.proType == 10903 || obj.changeproType == 10903) {
								this.htRouteArr.push(obj);
							} else {								
								this.routeArr.push(obj);
							}
                            console.log(this.htRouteArr,"this.htRouteArr",this.routeArr,"this.routeArr")
      
                        })

				}).catch((err)=>{
					this.$message({
						type:'error',
						message: err.tips
					})
				})	
			},
                        			// 审批人信息

			//审批
			approvalSend(){
                if(this.active==0 && !this.appRemark){
                   		this.$message({
						message: '审批拒绝需要填写拒绝原因',
						type: 'info'
					})
                }else{
                    let queryParams = {};
					queryParams = {
					    appRemark: this.appRemark,
					    completeState: this.active,
					    completeWay: 1,
					    orderId: this.query.orderId,
					    workItemId: this.query.workItemId,
					}
				this.$conn.getConn('commu.myAppDetail',{jsfile:false})(queryParams).then((res)=>{
					this.$message({
						type:'success',
						message: '审批成功'
					})
					setTimeout(()=>{
						this.spClose();
					},500)
				}).catch((err)=>{
					this.$message({
						type:'error',
						message: err.tips
					})
				})
                }
			},
			showProgress(info,currentSpjd,status){
					// 进度
					this.spjd = info.spjd; //当前审批进度（1/2）
					this.gzdetail.currentLevel = currentSpjd; //当前到谁审批
					this.gzdetail.flowState = status;  // 当前审人状态
					
			},
			// 关闭审批
			spClose(){
				window.close();
			}
		}
	}
</script>

<style scoped lang="scss">
	 .travelChangeInfoDel {
	display: inline-block;
	width: 16px; 
	height: 16px;
	background: #ff6666;
	color: #fff;
	line-height: 16px;
	font-size: 12px; 
	text-align: center;
}
	.ad-main-c{
		background-color: #EEF1F6;
		padding:40px 0;
		.main{
            .allBtn{
                display: flex;
                justify-content: center;
                align-items: center;
            }
			margin: 0 auto;
			width: 1200px;
			.flex{
				display: flex;	
			}
            .color007 {
                color: #007aff;
                }
			.between{
				justify-content: space-between;
			}
			.marB10{
				margin-bottom: 10px;
			}
			.border{
				border: 1px solid #E6E6E6;
			}
			.h80{
				min-height: 80px;
			}
            .ml20{
                margin-left: 20px;
            }
			.btn{
				width: 80px;
				height: 36px;
				font-size: 14px;
			}
			.leftTitle{
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #FAFAFA;
				border-right: 1px solid #E6E6E6;
				width: 120px;
			}
			.padTB30{
				padding:30px 0;
			}
			.sp-main{
				width: 1036px;
				padding: 20px 0 20px 40px;
				.ss-item{
					text-align: left;
					line-height: 30px;
					width: 100px;
				}
				.ss-right{
					width: 700px;
				}
				.sp-item{
					font-size: 14px;	
				}
			}
			.ys-main{
				width: 1036px;
				text-align: left;
				padding: 15px 0;
				.xc-ys{
					padding-left: 40px;
					line-height: 40px;
					.bfb-w25{
						width: 25%;
						display: inline-block;
					}
				}
			}
			.xc-main{
				width: 1036px;
				text-align: left;
				padding:20px 40px;
				
				.xc-text{
					padding: 24px 0 ;
					line-height: 30px;
				}
				
				.bfb-w5{
					width: 5%;
				}
				.bfb-w8{
					width: 8%;
				}
				.bfb-w10{
					width: 10%;
				}
				.bfb-w20{
					width: 20%;
				}
			}
			.right-main{
				width: 1036px;
				flex-wrap: wrap;
				padding: 10px 0;
				.topItem{
					width: 33%;
					text-align: left;
					padding-left: 40px;
					line-height: 30px;
				}
				.title-width{
					width: 80px;
					display: inline-block;
				}
			}
		}
	}
</style>
